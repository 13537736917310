import React from 'react';

const Logo = () => (
  <svg
    className="flex-no-shrink overflow-visible py-2 w-8 z-10"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.41421"
    clipRule="evenodd"
    viewBox="0 0 237 230"
  >
    <ellipse
      cx="118.346"
      cy="216.685"
      fill="url(#_Radial1)"
      rx="118.346"
      ry="13.076"
    />
    <g className="bounce">
      <path
        fill="#1b4590"
        fillRule="nonzero"
        d="M113.676 210.452h-2.772c-57.175 0-103.949-46.778-103.949-103.949 0-57.175 46.778-103.95 103.949-103.95h2.772c57.174 0 103.949 46.779 103.949 103.95.004 57.175-46.775 103.949-103.949 103.949z"
      />
      <path
        fill="#1a4690"
        fillRule="nonzero"
        d="M113.834 209.85h-2.763c-56.965 0-103.573-46.608-103.573-103.573 0-56.965 46.608-103.573 103.573-103.573h2.763c56.965 0 103.573 46.608 103.573 103.573 0 56.965-46.608 103.573-103.573 103.573z"
      />
      <path
        fill="#194691"
        fillRule="nonzero"
        d="M113.993 209.243h-2.752c-56.754 0-103.192-46.438-103.192-103.192 0-56.755 46.438-103.192 103.192-103.192h2.752c56.754 0 103.192 46.437 103.192 103.192 0 56.758-46.438 103.192-103.192 103.192z"
      />
      <path
        fill="#184791"
        fillRule="nonzero"
        d="M114.151 208.64h-2.743c-56.549 0-102.812-46.267-102.812-102.811 0-56.548 46.267-102.812 102.812-102.812h2.743c56.549 0 102.812 46.268 102.812 102.812S170.696 208.64 114.151 208.64z"
      />
      <path
        fill="#174791"
        fillRule="nonzero"
        d="M114.31 208.034h-2.732c-56.338 0-102.431-46.097-102.431-102.431C9.147 49.265 55.24 3.172 111.578 3.172h2.732c56.338 0 102.431 46.093 102.431 102.431 0 56.338-46.093 102.431-102.431 102.431z"
      />
      <path
        fill="#164892"
        fillRule="nonzero"
        d="M114.465 207.431h-2.724c-56.128 0-102.055-45.922-102.055-102.054 0-56.128 45.923-102.054 102.055-102.054h2.724c56.128 0 102.054 45.922 102.054 102.054 0 56.128-45.922 102.054-102.054 102.054z"
      />
      <path
        fill="#154892"
        fillRule="nonzero"
        d="M114.623 206.824h-2.712c-55.922 0-101.673-45.751-101.673-101.673 0-55.922 45.751-101.674 101.673-101.674h2.712c55.922 0 101.674 45.752 101.674 101.674 0 55.922-45.752 101.673-101.674 101.673z"
      />
      <path
        fill="#144993"
        fillRule="nonzero"
        d="M114.782 206.222h-2.704c-55.712 0-101.293-45.581-101.293-101.293S56.366 3.636 112.078 3.636h2.704c55.711 0 101.293 45.581 101.293 101.293s-45.582 101.293-101.293 101.293z"
      />
      <path
        fill="#134993"
        fillRule="nonzero"
        d="M114.94 205.615h-2.692c-55.501 0-100.916-45.411-100.916-100.916 0-55.502 45.411-100.916 100.916-100.916h2.692c55.502 0 100.917 45.41 100.917 100.916-.004 55.505-45.415 100.916-100.917 100.916z"
      />
      <path
        fill="#124a93"
        fillRule="nonzero"
        d="M115.099 205.012h-2.68c-55.296 0-100.536-45.24-100.536-100.535 0-55.296 45.24-100.536 100.536-100.536h2.68c55.295 0 100.536 45.24 100.536 100.536 0 55.295-45.245 100.535-100.536 100.535z"
      />
      <path
        fill="#114a94"
        fillRule="nonzero"
        d="M115.258 204.406h-2.673c-55.085 0-100.155-45.07-100.155-100.155S57.5 4.096 112.585 4.096h2.673c55.085 0 100.155 45.07 100.155 100.155s-45.07 100.155-100.155 100.155z"
      />
      <path
        fill="#0f4b94"
        fillRule="nonzero"
        d="M115.412 203.803h-2.66c-54.875 0-99.775-44.899-99.775-99.774s44.9-99.775 99.775-99.775h2.66c54.875 0 99.775 44.9 99.775 99.775.004 54.875-44.896 99.774-99.775 99.774z"
      />
      <path
        fill="#0e4b95"
        fillRule="nonzero"
        d="M115.571 203.197h-2.653c-54.669 0-99.397-44.729-99.397-99.398S58.249 4.401 112.918 4.401h2.653c54.669 0 99.397 44.729 99.397 99.398s-44.728 99.398-99.397 99.398z"
      />
      <path
        fill="#0c4c95"
        fillRule="nonzero"
        d="M115.729 202.594h-2.64c-54.459 0-99.017-44.558-99.017-99.017 0-54.459 44.558-99.017 99.017-99.017h2.64c54.459 0 99.017 44.558 99.017 99.017 0 54.459-44.558 99.017-99.017 99.017z"
      />
      <path
        fill="#0a4c95"
        fillRule="nonzero"
        d="M115.888 201.987h-2.633c-54.248 0-98.636-44.388-98.636-98.636 0-54.249 44.388-98.637 98.636-98.637h2.633c54.249 0 98.636 44.388 98.636 98.637 0 54.252-44.387 98.636-98.636 98.636z"
      />
      <path
        fill="#094d96"
        fillRule="nonzero"
        d="M116.047 201.385h-2.621c-54.043 0-98.256-44.218-98.256-98.256 0-54.043 44.217-98.256 98.256-98.256h2.621c54.042 0 98.255 44.217 98.255 98.256 0 54.038-44.217 98.256-98.255 98.256z"
      />
      <path
        fill="#074e96"
        fillRule="nonzero"
        d="M116.205 200.778h-2.613c-53.832 0-97.879-44.047-97.879-97.879 0-53.833 44.047-97.879 97.879-97.879h2.613c53.833 0 97.879 44.046 97.879 97.879-.004 53.836-44.046 97.879-97.879 97.879z"
      />
      <path
        fill="#054e97"
        fillRule="nonzero"
        d="M116.36 200.175h-2.601c-53.626 0-97.499-43.876-97.499-97.498 0-53.626 43.877-97.499 97.499-97.499h2.601c53.626 0 97.498 43.877 97.498 97.499 0 53.622-43.872 97.498-97.498 97.498z"
      />
      <path
        fill="#034f97"
        fillRule="nonzero"
        d="M116.518 199.569h-2.589c-53.416 0-97.118-43.702-97.118-97.118S60.513 5.333 113.929 5.333h2.589c53.416 0 97.118 43.702 97.118 97.118s-43.702 97.118-97.118 97.118z"
      />
      <path
        fill="#014f97"
        fillRule="nonzero"
        d="M116.677 198.966h-2.581c-53.206 0-96.741-43.531-96.741-96.741 0-53.206 43.531-96.741 96.741-96.741h2.581c53.206 0 96.741 43.531 96.741 96.741 0 53.206-43.535 96.741-96.741 96.741z"
      />
      <path
        fill="#005098"
        fillRule="nonzero"
        d="M116.836 198.359h-2.57c-52.999 0-96.36-43.361-96.36-96.36 0-53 43.361-96.361 96.36-96.361h2.57c52.999 0 96.36 43.361 96.36 96.361 0 52.999-43.365 96.36-96.36 96.36z"
      />
      <path
        fill="#005098"
        fillRule="nonzero"
        d="M116.994 197.757h-2.561c-52.79 0-95.98-43.191-95.98-95.98 0-52.79 43.19-95.98 95.98-95.98h2.561c52.79 0 95.98 43.19 95.98 95.98 0 52.789-43.19 95.98-95.98 95.98z"
      />
      <path
        fill="#005199"
        fillRule="nonzero"
        d="M117.149 197.15h-2.55C62.02 197.15 19 154.13 19 101.551c0-52.58 43.02-95.6 95.599-95.6h2.55c52.579 0 95.599 43.02 95.599 95.6.004 52.579-43.016 95.599-95.599 95.599z"
      />
      <path
        fill="#005199"
        fillRule="nonzero"
        d="M117.307 196.547h-2.541c-52.373 0-95.223-42.849-95.223-95.222 0-52.374 42.85-95.223 95.223-95.223h2.541c52.374 0 95.223 42.849 95.223 95.223 0 52.373-42.849 95.222-95.223 95.222z"
      />
      <path
        fill="#005299"
        fillRule="nonzero"
        d="M117.466 195.941h-2.53c-52.163 0-94.842-42.679-94.842-94.842S62.773 6.257 114.936 6.257h2.53c52.163 0 94.842 42.679 94.842 94.842s-42.679 94.842-94.842 94.842z"
      />
      <path
        fill="#00529a"
        fillRule="nonzero"
        d="M117.625 195.338h-2.522c-51.953 0-94.461-42.508-94.461-94.461 0-51.953 42.508-94.462 94.461-94.462h2.522c51.953 0 94.461 42.509 94.461 94.462s-42.508 94.461-94.461 94.461z"
      />
      <path
        fill="#00539a"
        fillRule="nonzero"
        d="M117.783 194.731h-2.51c-51.746 0-94.08-42.338-94.08-94.08 0-51.747 42.338-94.081 94.08-94.081h2.51c51.747 0 94.081 42.338 94.081 94.081 0 51.742-42.338 94.08-94.081 94.08z"
      />
      <path
        fill="#00539b"
        fillRule="nonzero"
        d="M117.942 194.129h-2.502c-51.537 0-93.704-42.168-93.704-93.704 0-51.537 42.167-93.704 93.704-93.704h2.502c51.536 0 93.704 42.167 93.704 93.704-.004 51.536-42.168 93.704-93.704 93.704z"
      />
      <path
        fill="#00549b"
        fillRule="nonzero"
        d="M118.096 193.522h-2.49c-51.326 0-93.323-41.997-93.323-93.323 0-51.327 41.997-93.324 93.323-93.324h2.49c51.327 0 93.324 41.997 93.324 93.324 0 51.326-41.993 93.323-93.324 93.323z"
      />
      <path
        fill="#00549b"
        fillRule="nonzero"
        d="M118.255 192.919h-2.478c-51.12 0-92.943-41.826-92.943-92.942 0-51.121 41.827-92.943 92.943-92.943h2.478c51.12 0 92.943 41.826 92.943 92.943.004 51.116-41.823 92.942-92.943 92.942z"
      />
      <path
        fill="#00559c"
        fillRule="nonzero"
        d="M118.414 192.313h-2.471c-50.91 0-92.566-41.656-92.566-92.566 0-50.911 41.656-92.566 92.566-92.566h2.471c50.91 0 92.566 41.655 92.566 92.566 0 50.914-41.656 92.566-92.566 92.566z"
      />
      <path
        fill="#00559c"
        fillRule="nonzero"
        d="M118.572 191.71h-2.458c-50.7 0-92.185-41.481-92.185-92.185 0-50.7 41.481-92.186 92.185-92.186h2.458c50.7 0 92.186 41.482 92.186 92.186 0 50.7-41.486 92.185-92.186 92.185z"
      />
      <path
        fill="#00569d"
        fillRule="nonzero"
        d="M118.731 191.103h-2.45c-50.494 0-91.805-41.311-91.805-91.804 0-50.494 41.311-91.805 91.805-91.805h2.45c50.494 0 91.805 41.311 91.805 91.805 0 50.493-41.315 91.804-91.805 91.804z"
      />
      <path
        fill="#00569d"
        fillRule="nonzero"
        d="M118.889 190.501h-2.438c-50.284 0-91.424-41.141-91.424-91.424 0-50.284 41.14-91.425 91.424-91.425h2.438c50.284 0 91.425 41.141 91.425 91.425 0 50.279-41.141 91.424-91.425 91.424z"
      />
      <path
        fill="#00579e"
        fillRule="nonzero"
        d="M119.044 189.894h-2.43c-50.074 0-91.048-40.97-91.048-91.047 0-50.074 40.97-91.048 91.048-91.048h2.43c50.074 0 91.048 40.97 91.048 91.048 0 50.077-40.97 91.047-91.048 91.047z"
      />
      <path
        fill="#00579e"
        fillRule="nonzero"
        d="M119.203 189.291h-2.419c-49.867 0-90.667-40.799-90.667-90.666 0-49.868 40.8-90.667 90.667-90.667h2.419c49.867 0 90.667 40.799 90.667 90.667 0 49.863-40.8 90.666-90.667 90.666z"
      />
      <path
        fill="#00589e"
        fillRule="nonzero"
        d="M119.361 188.685h-2.41c-49.658 0-90.287-40.629-90.287-90.286 0-49.658 40.629-90.287 90.287-90.287h2.41c49.658 0 90.287 40.629 90.287 90.287 0 49.657-40.629 90.286-90.287 90.286z"
      />
      <path
        fill="#00589f"
        fillRule="nonzero"
        d="M119.52 188.082h-2.399c-49.451 0-89.909-40.458-89.909-89.909 0-49.452 40.458-89.91 89.909-89.91h2.399c49.451 0 89.909 40.458 89.909 89.91-.004 49.451-40.462 89.909-89.909 89.909z"
      />
      <path
        fill="#00599f"
        fillRule="nonzero"
        d="M119.678 187.475h-2.386c-49.241 0-89.529-40.288-89.529-89.528 0-49.241 40.288-89.529 89.529-89.529h2.386c49.241 0 89.529 40.288 89.529 89.529-.004 49.24-40.288 89.528-89.529 89.528z"
      />
      <path
        fill="#0059a0"
        fillRule="nonzero"
        d="M119.837 186.873h-2.379c-49.031 0-89.148-40.118-89.148-89.149 0-49.03 40.117-89.148 89.148-89.148h2.379c49.031 0 89.148 40.118 89.148 89.148 0 49.031-40.117 89.149-89.148 89.149z"
      />
      <path
        fill="#005aa0"
        fillRule="nonzero"
        d="M119.992 186.266h-2.367c-48.825 0-88.768-39.947-88.768-88.768 0-48.824 39.947-88.767 88.768-88.767h2.367c48.824 0 88.767 39.947 88.767 88.767.004 48.821-39.943 88.768-88.767 88.768z"
      />
      <path
        fill="#005aa1"
        fillRule="nonzero"
        d="M120.15 185.659h-2.359c-48.614 0-88.391-39.776-88.391-88.39 0-48.615 39.777-88.391 88.391-88.391h2.359c48.615 0 88.391 39.776 88.391 88.391 0 48.618-39.776 88.39-88.391 88.39z"
      />
      <path
        fill="#005ba1"
        fillRule="nonzero"
        d="M120.309 185.057h-2.347c-48.405 0-88.011-39.606-88.011-88.011 0-48.404 39.606-88.01 88.011-88.01h2.347c48.404 0 88.01 39.606 88.01 88.01 0 48.405-39.606 88.011-88.01 88.011z"
      />
      <path
        fill="#005ba1"
        fillRule="nonzero"
        d="M120.468 184.45h-2.34c-48.198 0-87.629-39.435-87.629-87.63 0-48.198 39.435-87.629 87.629-87.629h2.34c48.198 0 87.629 39.435 87.629 87.629 0 48.199-39.435 87.63-87.629 87.63z"
      />
      <path
        fill="#005ba2"
        fillRule="nonzero"
        d="M120.626 183.847h-2.327c-47.988 0-87.249-39.261-87.249-87.249 0-47.988 39.261-87.249 87.249-87.249h2.327c47.988 0 87.249 39.261 87.249 87.249 0 47.988-39.261 87.249-87.249 87.249z"
      />
      <path
        fill="#005ca2"
        fillRule="nonzero"
        d="M120.781 183.241h-2.32c-47.778 0-86.872-39.091-86.872-86.873 0-47.777 39.09-86.872 86.872-86.872h2.32c47.778 0 86.872 39.091 86.872 86.872 0 47.782-39.09 86.873-86.872 86.873z"
      />
      <path
        fill="#005ca3"
        fillRule="nonzero"
        d="M120.939 182.638h-2.307c-47.572 0-86.492-38.92-86.492-86.492 0-47.571 38.92-86.491 86.492-86.491h2.307c47.572 0 86.492 38.92 86.492 86.491 0 47.572-38.92 86.492-86.492 86.492z"
      />
      <path
        fill="#005da3"
        fillRule="nonzero"
        d="M121.098 182.032h-2.296c-47.361 0-86.111-38.75-86.111-86.112 0-47.361 38.75-86.111 86.111-86.111h2.296c47.361 0 86.111 38.75 86.111 86.111 0 47.362-38.75 86.112-86.111 86.112z"
      />
      <path
        fill="#005da4"
        fillRule="nonzero"
        d="M121.257 181.429h-2.288c-47.152 0-85.735-38.579-85.735-85.735 0-47.151 38.579-85.734 85.735-85.734h2.288c47.151 0 85.734 38.579 85.734 85.734-.004 47.156-38.583 85.735-85.734 85.735z"
      />
      <path
        fill="#005ea4"
        fillRule="nonzero"
        d="M121.415 180.822h-2.276c-46.945 0-85.354-38.408-85.354-85.354 0-46.945 38.409-85.353 85.354-85.353h2.276c46.945 0 85.354 38.408 85.354 85.353 0 46.946-38.409 85.354-85.354 85.354z"
      />
      <path
        fill="#005ea4"
        fillRule="nonzero"
        d="M121.574 180.22h-2.268c-46.735 0-84.973-38.239-84.973-84.974 0-46.735 38.238-84.973 84.973-84.973h2.268c46.735 0 84.973 38.238 84.973 84.973s-38.238 84.974-84.973 84.974z"
      />
      <path
        fill="#005fa5"
        fillRule="nonzero"
        d="M121.728 179.613h-2.256c-46.525 0-84.592-38.068-84.592-84.593 0-46.525 38.067-84.592 84.592-84.592h2.256c46.525 0 84.593 38.067 84.593 84.592.004 46.525-38.064 84.593-84.593 84.593z"
      />
      <path
        fill="#005fa5"
        fillRule="nonzero"
        d="M121.887 179.01h-2.248c-46.319 0-84.216-37.897-84.216-84.216 0-46.318 37.897-84.215 84.216-84.215h2.248c46.319 0 84.216 37.897 84.216 84.215 0 46.319-37.897 84.216-84.216 84.216z"
      />
      <path
        fill="#0060a6"
        fillRule="nonzero"
        d="M122.046 178.404h-2.237c-46.108 0-83.835-37.727-83.835-83.836 0-46.108 37.727-83.835 83.835-83.835h2.237c46.108 0 83.835 37.727 83.835 83.835 0 46.113-37.727 83.836-83.835 83.836z"
      />
      <path
        fill="#0060a6"
        fillRule="nonzero"
        d="M122.204 177.801h-2.228c-45.899 0-83.455-37.556-83.455-83.455 0-45.898 37.556-83.454 83.455-83.454h2.228c45.899 0 83.455 37.556 83.455 83.454 0 45.899-37.556 83.455-83.455 83.455z"
      />
      <path
        fill="#0061a7"
        fillRule="nonzero"
        d="M122.363 177.194h-2.217c-45.692 0-83.074-37.385-83.074-83.074 0-45.692 37.386-83.074 83.074-83.074h2.217c45.692 0 83.074 37.386 83.074 83.074 0 45.693-37.382 83.074-83.074 83.074z"
      />
      <path
        fill="#0061a7"
        fillRule="nonzero"
        d="M122.521 176.592h-2.204c-45.482 0-82.697-37.216-82.697-82.698 0-45.482 37.215-82.697 82.697-82.697h2.204c45.482 0 82.698 37.215 82.698 82.697-.004 45.482-37.216 82.698-82.698 82.698z"
      />
      <path
        fill="#0062a7"
        fillRule="nonzero"
        d="M122.676 175.985h-2.197c-45.276 0-82.316-37.041-82.316-82.317 0-45.276 37.04-82.316 82.316-82.316h2.197c45.276 0 82.317 37.04 82.317 82.316s-37.041 82.317-82.317 82.317z"
      />
      <path
        fill="#0062a8"
        fillRule="nonzero"
        d="M122.835 175.382h-2.185c-45.066 0-81.936-36.87-81.936-81.936 0-45.066 36.87-81.936 81.936-81.936h2.185c45.065 0 81.936 36.87 81.936 81.936 0 45.062-36.871 81.936-81.936 81.936z"
      />
      <path
        fill="#0062a8"
        fillRule="nonzero"
        d="M122.993 174.776h-2.177c-44.855 0-81.559-36.7-81.559-81.56 0-44.855 36.7-81.559 81.559-81.559h2.177c44.856 0 81.56 36.7 81.56 81.559 0 44.86-36.704 81.56-81.56 81.56z"
      />
      <path
        fill="#0063a9"
        fillRule="nonzero"
        d="M123.152 174.173h-2.165c-44.65 0-81.179-36.529-81.179-81.179 0-44.649 36.529-81.178 81.179-81.178h2.165c44.649 0 81.178 36.529 81.178 81.178 0 44.65-36.533 81.179-81.178 81.179z"
      />
      <path
        fill="#0063a9"
        fillRule="nonzero"
        d="M123.31 173.566h-2.157c-44.439 0-80.798-36.358-80.798-80.798 0-44.439 36.359-80.798 80.798-80.798h2.157c44.44 0 80.798 36.359 80.798 80.798 0 44.44-36.358 80.798-80.798 80.798z"
      />
      <path
        fill="#0064aa"
        fillRule="nonzero"
        d="M123.465 172.964h-2.145c-44.229 0-80.417-36.189-80.417-80.418 0-44.229 36.188-80.417 80.417-80.417h2.145c44.229 0 80.417 36.188 80.417 80.417.004 44.229-36.184 80.418-80.417 80.418z"
      />
      <path
        fill="#0064aa"
        fillRule="nonzero"
        d="M123.624 172.357h-2.137c-44.023 0-80.041-36.018-80.041-80.041 0-44.023 36.018-80.04 80.041-80.04h2.137c44.023 0 80.04 36.017 80.04 80.04s-36.017 80.041-80.04 80.041z"
      />
      <path
        fill="#0065ab"
        fillRule="nonzero"
        d="M123.782 171.754h-2.125c-43.813 0-79.66-35.847-79.66-79.66 0-43.813 35.847-79.66 79.66-79.66h2.125c43.813 0 79.66 35.847 79.66 79.66 0 43.813-35.847 79.66-79.66 79.66z"
      />
      <path
        fill="#0065ab"
        fillRule="nonzero"
        d="M123.941 171.148h-2.113c-43.603 0-79.28-35.677-79.28-79.28 0-43.602 35.677-79.279 79.28-79.279h2.113c43.603 0 79.279 35.677 79.279 79.279 0 43.603-35.676 79.28-79.279 79.28z"
      />
      <path
        fill="#0066ab"
        fillRule="nonzero"
        d="M124.099 170.545h-2.105c-43.397 0-78.899-35.506-78.899-78.899 0-43.396 35.506-78.899 78.899-78.899h2.105c43.397 0 78.899 35.507 78.899 78.899 0 43.393-35.502 78.899-78.899 78.899z"
      />
      <path
        fill="#0066ac"
        fillRule="nonzero"
        d="M124.258 169.938h-2.093c-43.187 0-78.523-35.335-78.523-78.522 0-43.186 35.336-78.522 78.523-78.522h2.093c43.186 0 78.522 35.336 78.522 78.522-.004 43.191-35.336 78.522-78.522 78.522z"
      />
      <path
        fill="#0067ac"
        fillRule="nonzero"
        d="M124.413 169.336h-2.086c-42.976 0-78.141-35.166-78.141-78.142s35.165-78.141 78.141-78.141h2.086c42.976 0 78.141 35.165 78.141 78.141 0 42.976-35.161 78.142-78.141 78.142z"
      />
      <path
        fill="#0067ad"
        fillRule="nonzero"
        d="M124.571 168.729h-2.073c-42.77 0-77.761-34.995-77.761-77.761 0-42.77 34.994-77.761 77.761-77.761h2.073c42.77 0 77.761 34.995 77.761 77.761.004 42.77-34.991 77.761-77.761 77.761z"
      />
      <path
        fill="#0068ad"
        fillRule="nonzero"
        d="M124.73 168.126h-2.066c-42.56 0-77.384-34.82-77.384-77.384 0-42.56 34.82-77.384 77.384-77.384h2.066c42.56 0 77.384 34.82 77.384 77.384 0 42.56-34.824 77.384-77.384 77.384z"
      />
      <path
        fill="#0068ae"
        fillRule="nonzero"
        d="M124.888 167.52h-2.053c-42.35 0-77.004-34.65-77.004-77.004 0-42.35 34.65-77.003 77.004-77.003h2.053c42.35 0 77.004 34.649 77.004 77.003s-34.654 77.004-77.004 77.004z"
      />
      <path
        fill="#0069ae"
        fillRule="nonzero"
        d="M125.047 166.917h-2.046c-42.143 0-76.623-34.479-76.623-76.623 0-42.143 34.48-76.623 76.623-76.623h2.046c42.144 0 76.623 34.48 76.623 76.623 0 42.14-34.479 76.623-76.623 76.623z"
      />
      <path
        fill="#0069af"
        fillRule="nonzero"
        d="M125.206 166.31h-2.034c-41.934 0-76.243-34.308-76.243-76.242 0-41.933 34.309-76.242 76.243-76.242h2.034c41.933 0 76.242 34.309 76.242 76.242 0 41.934-34.309 76.242-76.242 76.242z"
      />
      <path
        fill="#0069af"
        fillRule="nonzero"
        d="M125.36 165.708h-2.022c-41.727 0-75.865-34.139-75.865-75.866s34.138-75.865 75.865-75.865h2.022c41.728 0 75.866 34.138 75.866 75.865 0 41.723-34.138 75.866-75.866 75.866z"
      />
      <path
        fill="#006ab0"
        fillRule="nonzero"
        d="M125.519 165.101h-2.014c-41.517 0-75.485-33.968-75.485-75.485 0-41.517 33.968-75.485 75.485-75.485h2.014c41.517 0 75.485 33.968 75.485 75.485 0 41.517-33.968 75.485-75.485 75.485z"
      />
      <path
        fill="#006ab0"
        fillRule="nonzero"
        d="M125.677 164.498h-2.002c-41.307 0-75.104-33.797-75.104-75.104S82.368 14.29 123.675 14.29h2.002c41.307 0 75.105 33.797 75.105 75.104s-33.798 75.104-75.105 75.104z"
      />
      <path
        fill="#006bb1"
        fillRule="nonzero"
        d="M125.836 163.892h-1.994c-41.101 0-74.728-33.627-74.728-74.724 0-41.101 33.627-74.728 74.728-74.728h1.994c41.101 0 74.724 33.627 74.724 74.728 0 41.097-33.627 74.724-74.724 74.724z"
      />
      <path
        fill="#006bb1"
        fillRule="nonzero"
        d="M125.995 163.289h-1.983c-40.89 0-74.347-33.456-74.347-74.347 0-40.891 33.457-74.347 74.347-74.347h1.983c40.89 0 74.347 33.456 74.347 74.347-.004 40.891-33.457 74.347-74.347 74.347z"
      />
      <path
        fill="#006cb2"
        fillRule="nonzero"
        d="M126.153 162.682h-1.974c-40.681 0-73.967-33.285-73.967-73.966 0-40.68 33.286-73.966 73.967-73.966h1.974c40.681 0 73.967 33.286 73.967 73.966 0 40.681-33.286 73.966-73.967 73.966z"
      />
      <path
        fill="#006cb2"
        fillRule="nonzero"
        d="M126.308 162.08h-1.963c-40.474 0-73.586-33.116-73.586-73.586 0-40.474 33.116-73.586 73.586-73.586h1.963c40.474 0 73.586 33.116 73.586 73.586.004 40.47-33.112 73.586-73.586 73.586z"
      />
      <path
        fill="#006db3"
        fillRule="nonzero"
        d="M126.467 161.473h-1.955c-40.264 0-73.209-32.945-73.209-73.209s32.945-73.209 73.209-73.209h1.955c40.264 0 73.209 32.945 73.209 73.209s-32.945 73.209-73.209 73.209z"
      />
      <path
        fill="#006db3"
        fillRule="nonzero"
        d="M126.625 160.871h-1.943c-40.054 0-72.828-32.775-72.828-72.829 0-40.054 32.774-72.828 72.828-72.828h1.943c40.054 0 72.829 32.774 72.829 72.828s-32.775 72.829-72.829 72.829z"
      />
      <path
        fill="#006eb4"
        fillRule="nonzero"
        d="M126.784 160.264h-1.931c-39.848 0-72.448-32.6-72.448-72.448 0-39.848 32.6-72.448 72.448-72.448h1.931c39.848 0 72.448 32.6 72.448 72.448 0 39.848-32.6 72.448-72.448 72.448z"
      />
      <path
        fill="#006eb4"
        fillRule="nonzero"
        d="M126.942 159.661h-1.923c-39.637 0-72.067-32.429-72.067-72.067 0-39.638 32.43-72.067 72.067-72.067h1.923c39.638 0 72.068 32.429 72.068 72.067 0 39.634-32.43 72.067-72.068 72.067z"
      />
      <path
        fill="#006fb4"
        fillRule="nonzero"
        d="M127.097 159.055h-1.911c-39.428 0-71.691-32.259-71.691-71.691 0-39.428 32.259-71.69 71.691-71.69h1.911c39.428 0 71.69 32.258 71.69 71.69 0 39.432-32.258 71.691-71.69 71.691z"
      />
      <path
        fill="#006fb5"
        fillRule="nonzero"
        d="M127.256 158.448h-1.904c-39.221 0-71.31-32.089-71.31-71.31 0-39.221 32.089-71.31 71.31-71.31h1.904c39.221 0 71.309 32.089 71.309 71.31 0 39.221-32.088 71.31-71.309 71.31z"
      />
      <path
        fill="#006fb5"
        fillRule="nonzero"
        d="M127.414 157.845h-1.891c-39.011 0-70.929-31.918-70.929-70.929s31.918-70.929 70.929-70.929h1.891c39.011 0 70.929 31.918 70.929 70.929s-31.918 70.929-70.929 70.929z"
      />
      <path
        fill="#0070b6"
        fillRule="nonzero"
        d="M127.573 157.239h-1.884c-38.801 0-70.552-31.748-70.552-70.553 0-38.801 31.747-70.553 70.552-70.553h1.884c38.801 0 70.552 31.748 70.552 70.553-.004 38.805-31.751 70.553-70.552 70.553z"
      />
      <path
        fill="#0070b6"
        fillRule="nonzero"
        d="M127.731 156.636h-1.871c-38.595 0-70.172-31.577-70.172-70.172s31.577-70.172 70.172-70.172h1.871c38.595 0 70.172 31.577 70.172 70.172s-31.577 70.172-70.172 70.172z"
      />
      <path
        fill="#0071b7"
        fillRule="nonzero"
        d="M127.89 156.029h-1.864c-38.384 0-69.791-31.406-69.791-69.791s31.407-69.791 69.791-69.791h1.864c38.385 0 69.791 31.406 69.791 69.791s-31.406 69.791-69.791 69.791z"
      />
      <path
        fill="#0071b7"
        fillRule="nonzero"
        d="M128.045 155.427h-1.852c-38.175 0-69.411-31.236-69.411-69.411s31.236-69.411 69.411-69.411h1.852c38.174 0 69.41 31.236 69.41 69.411.004 38.175-31.232 69.411-69.41 69.411z"
      />
      <path
        fill="#0072b8"
        fillRule="nonzero"
        d="M128.203 154.82h-1.844c-37.968 0-69.034-31.066-69.034-69.034 0-37.969 31.066-69.034 69.034-69.034h1.844c37.969 0 69.034 31.065 69.034 69.034 0 37.972-31.065 69.034-69.034 69.034z"
      />
      <path
        fill="#0072b9"
        fillRule="nonzero"
        d="M128.362 154.217h-1.832c-37.758 0-68.653-30.895-68.653-68.653 0-37.758 30.895-68.653 68.653-68.653h1.832c37.758 0 68.653 30.895 68.653 68.653 0 37.758-30.895 68.653-68.653 68.653z"
      />
      <path
        fill="#0073b9"
        fillRule="nonzero"
        d="M128.52 153.611h-1.82c-37.552 0-68.272-30.725-68.272-68.273 0-37.552 30.724-68.273 68.272-68.273h1.82c37.553 0 68.273 30.725 68.273 68.273 0 37.552-30.724 68.273-68.273 68.273z"
      />
      <path
        fill="#0073ba"
        fillRule="nonzero"
        d="M128.679 153.008h-1.812c-37.342 0-67.892-30.554-67.892-67.892 0-37.342 30.554-67.892 67.892-67.892h1.812c37.342 0 67.892 30.554 67.892 67.892 0 37.338-30.55 67.892-67.892 67.892z"
      />
      <path
        fill="#0074ba"
        fillRule="nonzero"
        d="M128.838 152.401h-1.801c-37.131 0-67.515-30.379-67.515-67.515 0-37.132 30.38-67.515 67.515-67.515h1.801c37.131 0 67.515 30.379 67.515 67.515-.004 37.136-30.384 67.515-67.515 67.515z"
      />
      <path
        fill="#0074bb"
        fillRule="nonzero"
        d="M128.992 151.799H127.2c-36.926 0-67.135-30.209-67.135-67.135S90.274 17.529 127.2 17.529h1.792c36.926 0 67.135 30.209 67.135 67.135 0 36.922-30.209 67.135-67.135 67.135z"
      />
      <path
        fill="#0075bb"
        fillRule="nonzero"
        d="M129.151 151.192h-1.78c-36.716 0-66.755-30.039-66.755-66.754 0-36.716 30.039-66.754 66.755-66.754h1.78c36.715 0 66.754 30.038 66.754 66.754 0 36.715-30.039 66.754-66.754 66.754z"
      />
      <path
        fill="#0075bc"
        fillRule="nonzero"
        d="M129.309 150.589h-1.772c-36.505 0-66.377-29.868-66.377-66.377 0-36.506 29.868-66.378 66.377-66.378h1.772c36.506 0 66.378 29.868 66.378 66.378 0 36.509-29.872 66.377-66.378 66.377z"
      />
      <path
        fill="#0076bc"
        fillRule="nonzero"
        d="M129.468 149.983h-1.76c-36.3 0-65.997-29.698-65.997-65.997s29.697-65.997 65.997-65.997h1.76c36.299 0 65.997 29.698 65.997 65.997s-29.698 65.997-65.997 65.997z"
      />
      <path
        fill="#0076bd"
        fillRule="nonzero"
        d="M129.627 149.38h-1.753c-36.089 0-65.616-29.527-65.616-65.616s29.527-65.616 65.616-65.616h1.753c36.089 0 65.616 29.527 65.616 65.616s-29.527 65.616-65.616 65.616z"
      />
      <path
        fill="#0077be"
        fillRule="nonzero"
        d="M129.785 148.773h-1.74c-35.879 0-65.236-29.356-65.236-65.235s29.357-65.236 65.236-65.236h1.74c35.879 0 65.236 29.357 65.236 65.236 0 35.879-29.357 65.235-65.236 65.235z"
      />
      <path
        fill="#0077be"
        fillRule="nonzero"
        d="M129.94 148.171h-1.729c-35.673 0-64.859-29.186-64.859-64.859 0-35.673 29.186-64.859 64.859-64.859h1.729c35.673 0 64.859 29.186 64.859 64.859 0 35.673-29.186 64.859-64.859 64.859z"
      />
      <path
        fill="#0078bf"
        fillRule="nonzero"
        d="M130.098 147.564h-1.72c-35.463 0-64.479-29.016-64.479-64.478 0-35.463 29.016-64.478 64.479-64.478h1.72c35.463 0 64.479 29.015 64.479 64.478 0 35.462-29.016 64.478-64.479 64.478z"
      />
      <path
        fill="#0078bf"
        fillRule="nonzero"
        d="M130.257 146.961h-1.709c-35.252 0-64.098-28.845-64.098-64.097 0-35.253 28.846-64.098 64.098-64.098h1.709c35.253 0 64.098 28.845 64.098 64.098 0 35.252-28.845 64.097-64.098 64.097z"
      />
      <path
        fill="#0079c0"
        fillRule="nonzero"
        d="M130.416 146.355h-1.701c-35.047 0-63.717-28.675-63.717-63.717 0-35.047 28.674-63.717 63.717-63.717h1.701c35.046 0 63.717 28.674 63.717 63.717 0 35.046-28.671 63.717-63.717 63.717z"
      />
      <path
        fill="#0079c1"
        fillRule="nonzero"
        d="M130.574 145.752h-1.689c-34.836 0-63.34-28.504-63.34-63.34s28.504-63.341 63.34-63.341h1.689c34.836 0 63.341 28.505 63.341 63.341-.004 34.836-28.505 63.34-63.341 63.34z"
      />
      <path
        fill="#007ac1"
        fillRule="nonzero"
        d="M130.729 145.145h-1.681c-34.626 0-62.96-28.333-62.96-62.959 0-34.626 28.334-62.96 62.96-62.96h1.681c34.626 0 62.96 28.334 62.96 62.96 0 34.63-28.33 62.959-62.96 62.959z"
      />
      <path
        fill="#007ac2"
        fillRule="nonzero"
        d="M130.887 144.543h-1.669c-34.42 0-62.579-28.159-62.579-62.579s28.159-62.579 62.579-62.579h1.669c34.42 0 62.579 28.159 62.579 62.579.004 34.416-28.159 62.579-62.579 62.579z"
      />
      <path
        fill="#007bc2"
        fillRule="nonzero"
        d="M131.046 143.936h-1.661c-34.21 0-62.203-27.989-62.203-62.202 0-34.21 27.989-62.203 62.203-62.203h1.661c34.21 0 62.202 27.989 62.202 62.203 0 34.213-27.992 62.202-62.202 62.202z"
      />
      <path
        fill="#007bc3"
        fillRule="nonzero"
        d="M131.205 143.333h-1.65c-34.003 0-61.822-27.818-61.822-61.821 0-34.004 27.819-61.822 61.822-61.822h1.65c34.003 0 61.821 27.818 61.821 61.822 0 33.999-27.822 61.821-61.821 61.821z"
      />
      <path
        fill="#007cc4"
        fillRule="nonzero"
        d="M131.363 142.727h-1.637c-33.794 0-61.441-27.648-61.441-61.441 0-33.794 27.647-61.441 61.441-61.441h1.637c33.794 0 61.441 27.647 61.441 61.441 0 33.793-27.647 61.441-61.441 61.441z"
      />
      <path
        fill="#007cc4"
        fillRule="nonzero"
        d="M131.522 142.124h-1.63c-33.583 0-61.06-27.477-61.06-61.06 0-33.584 27.477-61.061 61.06-61.061h1.63c33.583 0 61.06 27.477 61.06 61.061 0 33.579-27.477 61.06-61.06 61.06z"
      />
      <path
        fill="#08255e"
        fillRule="nonzero"
        d="M112.161 213.192c57.857 0 105.194-46.715 105.194-103.811 0-.487-.031-.967-.035-1.451-.797 56.43-47.794 102.36-105.155 102.36C54.8 210.29 7.807 164.364 7.01 107.93c-.008.484-.036.964-.036 1.451-.008 57.096 47.33 103.811 105.187 103.811z"
      />
      <path
        fill="#1e4079"
        fillRule="nonzero"
        d="M9.397 108.049c0-35.462 18.14-66.94 45.704-85.873 2.744-2.177 5.591-4.227 8.549-6.134C30.518 33.781 7.89 68.431 7.89 108.049c0 57.52 47.691 104.584 105.98 104.584.289 0 .579-.012.868-.016-57.995-.341-105.341-47.254-105.341-104.568z"
      />
      <path
        fill="#007cc4"
        fillRule="nonzero"
        d="M112.292 0C54.356 0 6.955 46.779 6.955 103.949c0 .488.031.968.035 1.456C7.787 48.896 54.847 2.906 112.292 2.906c57.44 0 104.5 45.99 105.301 102.499.008-.488.036-.968.036-1.456C217.629 46.779 170.224 0 112.292 0z"
      />
      <path
        fill="url(#_Linear2)"
        fillRule="nonzero"
        d="M118.207 200.132c-57.936 0-105.337-46.779-105.337-103.95 0-14.194 2.93-27.747 8.208-40.106-8.945 15.262-14.104 32.93-14.104 51.735 0 57.175 47.402 103.95 105.338 103.95 43.551 0 81.143-26.435 97.129-63.848-18.25 31.137-52.357 52.219-91.234 52.219z"
      />
      <path
        fill="#fff"
        fillOpacity=".14902"
        fillRule="nonzero"
        d="M104.053 184.759c-9.988.817-20.246-.963-29.658-4.746-9.449-3.75-18.053-9.531-25.297-16.625-3.584-3.584-6.855-7.478-9.702-11.657-2.799-4.203-5.274-8.631-7.149-13.302-3.834-9.29-5.634-19.417-4.802-29.274.825 9.838 3.426 19.334 7.621 28.053 2.129 4.341 4.552 8.532 7.407 12.434 2.807 3.925 5.951 7.62 9.413 10.995 6.855 6.819 14.924 12.446 23.738 16.605 8.838 4.143 18.461 6.713 28.429 7.517z"
      />
      <path
        fill="#fff"
        fillOpacity=".07"
        fillRule="nonzero"
        d="M199.969 111.852c-2.958 22.18-15.17 41.2-32.148 53.725 0 0 1.507.959 2.51 3.311.876 2.057.262 4.333.262 4.333 19.805-13.738 33.849-35.308 37.139-60.66l-7.763-.709zm4.04-2.847c-1.875-.091-3.56 1.146-4.04 2.843l7.767.713c-.154-1.903-1.724-3.457-3.727-3.556z"
      />
      <path
        fill="#fff"
        fillOpacity=".14902"
        fillRule="nonzero"
        d="M113.743 92.063c43.852 0 79.398 46.675 79.398 4.861 0-41.815-35.55-81.266-79.398-81.266-43.853 0-79.402 39.451-79.402 81.266 0 41.814 35.549-4.861 79.402-4.861z"
      />
      <path
        fill="url(#_Linear3)"
        fillRule="nonzero"
        d="M12.081 111.205c0-57.174 47.401-103.949 105.337-103.949 26.574 0 50.919 9.861 69.502 26.034-19.111-18.988-45.545-30.792-74.644-30.792C54.34 2.498 6.939 49.277 6.939 106.447c0 30.947 13.905 58.833 35.835 77.916-18.941-18.822-30.693-44.701-30.693-73.158z"
      />
      <path
        fill="url(#_Radial4)"
        fillRule="nonzero"
        d="M132.549 1.963c-15.436 13.215-25.194 32.715-25.194 54.462 0 39.809 32.703 72.083 73.043 72.083 13.604 0 26.331-3.671 37.239-10.063.424-3.889.65-7.834.65-11.839-.004-52.119-36.953-95.48-85.738-104.643z"
      />
    </g>
    <defs>
      <radialGradient
        id="_Radial1"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(118.348 0 0 13.0772 118.344 216.695)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#1b4590" />
        <stop offset=".05" stopColor="#1c438a" stopOpacity=".941176" />
        <stop offset=".38" stopColor="#1d356b" stopOpacity=".619608" />
        <stop offset=".65" stopColor="#182a54" stopOpacity=".341176" />
        <stop offset=".87" stopColor="#132246" stopOpacity=".121569" />
        <stop offset={1} stopColor="#101e41" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="_Linear2"
        x2={1}
        gradientTransform="matrix(41.8695 -70.4743 70.4743 41.8695 4009.92 2547.14)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#194087" />
        <stop offset=".11" stopColor="#1b3b7c" stopOpacity=".886275" />
        <stop offset=".4" stopColor="#193062" stopOpacity=".603922" />
        <stop offset=".65" stopColor="#15264f" stopOpacity=".34902" />
        <stop offset=".86" stopColor="#112045" stopOpacity=".137255" />
        <stop offset={1} stopColor="#0f1e41" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="_Linear3"
        x2={1}
        gradientTransform="matrix(-105.222 -127.956 127.956 -105.222 422.645 -167.258)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#0f1e41" stopOpacity={0} />
        <stop offset=".26" stopColor="#0f1e41" stopOpacity={0} />
        <stop offset=".36" stopColor="#112045" stopOpacity=".137255" />
        <stop offset=".5" stopColor="#15264f" stopOpacity=".34902" />
        <stop offset=".68" stopColor="#193062" stopOpacity=".603922" />
        <stop offset=".88" stopColor="#1b3b7c" stopOpacity=".886275" />
        <stop offset=".96" stopColor="#194087" />
        <stop offset={1} stopColor="#194087" />
      </linearGradient>
      <radialGradient
        id="_Radial4"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="translate(178.314 55.4245) scale(58.3106)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);

export default Logo;
